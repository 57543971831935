import { BaseEditModalProps } from '@monorepo/common/component/modal/EditModal';
import SimpleModal from '@monorepo/common/component/modal/SimpleModal';
import NoData from '@monorepo/common/component/NoData';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { Alert } from 'reactstrap';
import { StampOfferDocument, StampOfferQuery, StampOfferQueryVariables } from '../../../graphql-operations';
import StampOfferDetail from './StampOfferDetail';

interface StampOfferModalProps extends BaseEditModalProps {
  title: string;
  clientId: string;
}

export default function StampOfferModal({ title, clientId, children }: StampOfferModalProps) {
  return (
    <SimpleModal
      title={title}
      size="lg"
      body={() => (
        <QueryWithLoader<StampOfferQuery, StampOfferQueryVariables>
          query={StampOfferDocument}
          variables={{ clientId }}
          fetchPolicy="network-only"
        >
          {({ data }) => {
            const stampOffer = data.stampOffer;
            const stampOfferItems = stampOffer.stampOfferItems;

            return (
              <>
                {/* Missing GDPR agreement error message */}
                {!stampOffer.hasGdpr && (
                  <Alert color="danger">
                    Nemáte platný souhlas s GDPR. Dostavte se prosím osobně do kanceláře Sokola a podepište nový.
                  </Alert>
                )}

                {stampOfferItems.map((stampOfferItem, index) => (
                  <div key={stampOfferItem.stampType}>
                    <StampOfferDetail
                      clientId={clientId}
                      stampOfferItem={stampOfferItem}
                      disabled={!stampOffer.hasGdpr}
                    />
                    {index < stampOfferItems.length - 1 && <hr />}
                  </div>
                ))}

                {stampOfferItems.length === 0 && <NoData text="Momentálně pro Vás nemáme žádné známky" />}
              </>
            );
          }}
        </QueryWithLoader>
      )}
      children={children}
    />
  );
}
