import { useMutation } from '@apollo/client';
import ConfirmModal from '@monorepo/common/component/modal/ConfirmModal';
import { ChildrenRenderProp } from '@monorepo/common/component/modal/EditModal';
import React, { useCallback } from 'react';
import {
  CreateInsuranceReceiptDocument,
  CreateInsuranceReceiptMutation,
  CreateInsuranceReceiptMutationVariables,
  Period,
  PersonDocument
} from '../../graphql-operations';
import { getServerBasename } from '../../util/Url';
import InsuranceReceiptDetail from './InsuranceReceiptDetail';

interface PaymentDisciplineType {
  id: string;
  disciplineConfig: {
    period: Period;
    discipline: {
      name: string;
      insuranceReceiptPurpose?: string | null;
    };
  };
  orderDiscipline: {
    priceDiscounted: number;
  };
}

interface InsuranceReceiptDetailNewModalProps {
  title: string;
  clientId: string;
  paymentDiscipline: PaymentDisciplineType;
  children: ChildrenRenderProp;
}

export default function InsuranceReceiptDetailNewModal({
  title,
  clientId,
  paymentDiscipline,
  children
}: InsuranceReceiptDetailNewModalProps) {
  const refetchQueries = () => {
    return [
      {
        query: PersonDocument,
        variables: { id: clientId }
      }
    ];
  };

  const [createInsuranceReceipt] = useMutation<CreateInsuranceReceiptMutation, CreateInsuranceReceiptMutationVariables>(
    CreateInsuranceReceiptDocument,
    {
      refetchQueries
    }
  );

  const handleConfirmClick = useCallback(
    (close: () => void) => {
      createInsuranceReceipt({
        variables: {
          paymentDisciplineId: paymentDiscipline.id
        }
      })
        .then(
          ({ data }) =>
            data?.createInsuranceReceipt?.id &&
            (window.location.href =
              getServerBasename() + `/rest/export/client/insurance-receipt/${data.createInsuranceReceipt.id}`)
        )
        .then(close);
    },
    [createInsuranceReceipt, paymentDiscipline.id]
  );

  const insuranceReceipt = {
    period: paymentDiscipline.disciplineConfig.period,
    purpose: paymentDiscipline.disciplineConfig.discipline.insuranceReceiptPurpose,
    amount: paymentDiscipline.orderDiscipline.priceDiscounted
  };

  return (
    <ConfirmModal
      title={title}
      size="md"
      cancelButtonText="Zrušit"
      confirmButtonText="Vytvořit"
      confirmButtonColor="primary"
      children={children}
      body={() => <InsuranceReceiptDetail insuranceReceipt={insuranceReceipt} paymentDiscipline={paymentDiscipline} />}
      onConfirm={close => handleConfirmClick(close)}
    />
  );
}
