import DisciplineNameLabel from '@monorepo/common/component/discipline/DisciplineNameLabel';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import OrderLink from '@monorepo/common/component/order/OrderLink';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import React from 'react';
import { Table } from 'reactstrap';
import { OrderTotalStatusEnum } from '../../graphql-operations';

interface OrderDisciplineRowProps {
  orderDiscipline: OrderDiscipline;
}

function OrderDisciplineRow({ orderDiscipline }: OrderDisciplineRowProps) {
  const {
    disciplineConfig: { period, discipline },
    orderTotal: { orderNumber, status },
    priceOrig,
    priceDiscounted,
    covidAccount
  } = orderDiscipline;

  const bonusAccounted = [OrderTotalStatusEnum.Unpaid, OrderTotalStatusEnum.Paying, OrderTotalStatusEnum.Paid].includes(
    status
  );

  return (
    <tr style={bonusAccounted ? undefined : { textDecoration: 'line-through' }}>
      <td>
        <PeriodLabel period={period} />
      </td>
      <td>
        <DisciplineNameLabel discipline={discipline} />
      </td>
      <td className="text-right">
        <OrderLink orderNumber={orderNumber}>
          <MoneyLabel amount={priceOrig} />
        </OrderLink>
      </td>
      <td className="text-right">
        <OrderLink orderNumber={orderNumber}>
          <MoneyLabel amount={priceDiscounted} />
        </OrderLink>
      </td>
      <td className="text-right">
        <OrderLink orderNumber={orderNumber}>
          <MoneyLabel amount={covidAccount} signed={true} />
        </OrderLink>
      </td>
    </tr>
  );
}

export interface OrderDiscipline {
  id: string;
  priceOrig: number;
  priceDiscounted: number;
  covidAccount: number;
  orderTotal: {
    id: string;
    orderNumber: string;
    status: OrderTotalStatusEnum;
  };
  disciplineConfig: {
    id: string;
    period: {
      id: string;
      year: number;
      number: number;
    };
    discipline: {
      id: string;
      name: string;
    };
  };
}

interface CovidAccountBalanceListProps {
  covidAccountBalance: number;
  ordersDiscipline: OrderDiscipline[];
}

export default function CovidAccountBalanceList({
  ordersDiscipline,
  covidAccountBalance
}: CovidAccountBalanceListProps) {
  return (
    <Table size="sm" striped>
      <thead>
        <tr>
          <th>Období</th>
          <th>Oddíl</th>
          <th className="text-right">Částka bez slevy</th>
          <th className="text-right">Částka po slevě</th>
          <th className="text-right">Covid účet</th>
        </tr>
      </thead>
      <tbody>
        {ordersDiscipline.map(od => (
          <OrderDisciplineRow key={od.id} orderDiscipline={od} />
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={4}>Zůstatek covid účtu</th>
          <td className="text-right">
            <MoneyLabel amount={covidAccountBalance} />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}
