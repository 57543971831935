import { useMutation } from '@apollo/client';
import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import DateRange from '@monorepo/common/component/DateRange';
import useConfirmModal from '@monorepo/common/component/modal/useConfirmModal';
import MoneyLabel, { formatMoney } from '@monorepo/common/component/MoneyLabel';
import OccupancyProgress from '@monorepo/common/component/OccupancyProgress';
import {
  AccompanistChoice,
  DisciplineAccompanistSelectFormType
} from '@monorepo/common/component/offer/accompanist/DisciplineAccompanistSelectForm';
import DisciplineAccompanistSelectModal from '@monorepo/common/component/offer/accompanist/DisciplineAccompanistSelectModal';
import TimeLabel from '@monorepo/common/component/TimeLabel';
import { DAYS_OF_WEEK } from '@monorepo/common/util/Time';
import React, { MouseEventHandler, ReactNode, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import {
  BasicClientFragment,
  CreateOrderForDisciplineDocument,
  CreateOrderForDisciplineMutation,
  CreateOrderForDisciplineMutationVariables,
  Period
} from '../../../graphql-operations';

interface InfoRowProps {
  label: string;
  children: ReactNode;
}

function InfoRow({ label, children }: InfoRowProps) {
  return (
    <Row>
      <Col sm={12} md={5}>
        <span className="text-muted">{label}</span>
      </Col>
      <Col sm={12} md={7}>
        {children}
      </Col>
    </Row>
  );
}

interface PayButtonLabelProps {
  priceDiscounted: number;
}

function PayButtonLabel({ priceDiscounted }: PayButtonLabelProps) {
  if (priceDiscounted === 0) {
    return <span>Přihlásit do oddílu</span>;
  }

  return <span>Zaplatit kartou</span>;
}

function getAccompanistSelectModalPayButtonText(priceDiscounted: number) {
  if (priceDiscounted === 0) {
    return 'Přihlásit do oddílu';
  }

  return `Zaplatit kartou ${formatMoney(priceDiscounted)}`;
}

const isAccompanist = (accompanist: AccompanistChoice | undefined): accompanist is AccompanistChoice => {
  return !!accompanist;
};

export interface DisciplineConfigOffer {
  price: number;
  priceDiscounted: number;
  covidDiscount: number;
  disciplineConfig: {
    id: string;
    discipline: {
      id: string;
      name: string;
      scheduleDay: number;
      scheduleTimeFrom: string;
      scheduleTimeTo: string;
    };
    period: Period;
    leader?: BasicClientFragment | null;
    occupancy: {
      paidCount: number;
      reservationsCount: number;
      capacity: number;
      full: boolean;
    };
    birthDateFrom?: string | null;
    birthDateTo?: string | null;
  };
  accompanistSelects: {
    disciplineAccompanist: {
      id: string;
      birthDateFrom?: string | null;
      birthDateTo?: string | null;
    };
    choices: {
      covidDiscount: number;
      hasStamp: boolean;
      client: BasicClientFragment;
      hasGdpr: boolean;
    }[];
  }[];
}

interface DisciplineConfigOfferDetailProps {
  clientId: string;
  disciplineConfigOffer: DisciplineConfigOffer;
  disabled: boolean;
}

export default function DisciplineConfigOfferDetail({
  clientId,
  disciplineConfigOffer,
  disabled
}: DisciplineConfigOfferDetailProps) {
  const { price, priceDiscounted, covidDiscount, disciplineConfig, accompanistSelects } = disciplineConfigOffer;
  const { discipline, leader, occupancy, birthDateFrom, birthDateTo } = disciplineConfig;
  const { name, scheduleDay, scheduleTimeFrom, scheduleTimeTo } = discipline;

  const history = useHistory();

  const [createOrder] = useMutation<CreateOrderForDisciplineMutation, CreateOrderForDisciplineMutationVariables>(
    CreateOrderForDisciplineDocument
  );

  const { modalProps, confirm } = useConfirmModal<DisciplineAccompanistSelectFormType>();

  const hasAnyAccompanistCovidDiscount = accompanistSelects
    .flatMap(select => select.choices)
    .map(choice => choice.covidDiscount)
    .some(cd => cd > 0);

  const [selectedAccompanistsDiscount, setSelectedAccompanistsDiscount] = useState(0);

  const updateSelectedAccompanistsDiscount = useCallback(
    ({ accompanists }: DisciplineAccompanistSelectFormType) => {
      // sum all selected accompanists discounts
      const accompanistsDiscount = accompanists
        .filter(isAccompanist)
        .map(ac => ac.covidDiscount)
        .reduce((a, b) => a + b, 0);

      // update the form
      setSelectedAccompanistsDiscount(accompanistsDiscount);
    },
    [setSelectedAccompanistsDiscount]
  );

  const priceDiscountedTotal = priceDiscounted - selectedAccompanistsDiscount;

  const onPayClick = useCallback<MouseEventHandler>(
    e => {
      e.preventDefault();
      const chooseAccompanist = accompanistSelects.length ? confirm() : Promise.resolve({ accompanists: [] });

      chooseAccompanist
        .then(({ accompanists }) =>
          createOrder({
            variables: {
              clientForId: clientId,
              disciplineConfigId: disciplineConfig.id,
              accompanistIds: accompanists.filter(isAccompanist).map(ac => ac.client.id),
              priceDiscounted: priceDiscountedTotal
            }
          })
        )
        .then(({ data }) => data && history.push(`/orders/${data.createOrderForDiscipline.orderNumber}`));
    },
    [accompanistSelects, confirm, createOrder, clientId, disciplineConfig.id, priceDiscountedTotal, history]
  );

  return (
    <Row className={disabled ? 'text-muted' : ''}>
      <Col sm={8}>
        <h3>{name}</h3>
        <InfoRow label="Kdy se cvičí">
          <span>
            {DAYS_OF_WEEK[scheduleDay]} (<TimeLabel timeString={scheduleTimeFrom} />
            {' - '}
            <TimeLabel timeString={scheduleTimeTo} />)
          </span>
        </InfoRow>

        {(birthDateFrom || birthDateTo) && (
          <InfoRow label="Hranice data narození">
            <DateRange dateFromString={birthDateFrom} dateToString={birthDateTo} />
          </InfoRow>
        )}

        {leader && (
          <InfoRow label="Vedoucí">
            <ClientNameLabel client={leader} />
          </InfoRow>
        )}

        <InfoRow label="Obsazenost">
          <OccupancyProgress
            paidCount={occupancy.paidCount}
            reservationsCount={occupancy.reservationsCount}
            capacity={occupancy.capacity}
          />
        </InfoRow>
      </Col>

      <Col sm={4} className="text-right">
        {priceDiscounted === price ? (
          <h3>
            <MoneyLabel amount={price} />
          </h3>
        ) : (
          <>
            <del className="text-muted">
              <h3>
                <MoneyLabel amount={price} />
              </h3>
            </del>
            <h3>
              {hasAnyAccompanistCovidDiscount && <span>max</span>} <MoneyLabel amount={priceDiscounted} />
            </h3>
            {covidDiscount > 0 && (
              <div>
                Sleva covid <MoneyLabel amount={covidDiscount} />
              </div>
            )}
            {hasAnyAccompanistCovidDiscount && <div>Cena je dále závislá na výběru doprovodu</div>}
          </>
        )}

        {!disabled && (
          <Button color="primary" onClick={onPayClick}>
            {!!accompanistSelects.length ? (
              <span>Výběr doprovodu</span>
            ) : (
              <PayButtonLabel priceDiscounted={priceDiscounted} />
            )}
          </Button>
        )}
      </Col>
      {!!accompanistSelects.length && (
        <DisciplineAccompanistSelectModal
          {...modalProps}
          accompanistSelects={accompanistSelects}
          confirmButtonText={getAccompanistSelectModalPayButtonText(priceDiscountedTotal)}
          title="Výběr doprovodu"
          onChange={updateSelectedAccompanistsDiscount}
        />
      )}
    </Row>
  );
}
