import React from 'react';
import CommonHeader from '@monorepo/common/layout/CommonHeader';

export default function Header() {
  const menuItems = [
    { url: '/persons', label: 'Rodina' },
    { url: '/orders', label: 'Objednávky' }
  ];

  return <CommonHeader menuItems={menuItems} />;
}
