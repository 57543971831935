import { useMutation } from '@apollo/client';
import useConfirmModal from '@monorepo/common/component/modal/useConfirmModal';
import MoneyLabel, { formatMoney } from '@monorepo/common/component/MoneyLabel';
import { TokenOfferPriceEditFormType } from '@monorepo/common/component/offer/token/TokenOfferPriceEditForm';
import TokenOfferPriceEditModal from '@monorepo/common/component/offer/token/TokenOfferPriceEditModal';
import React, { MouseEventHandler, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import {
  CreateOrderForTokenDocument,
  CreateOrderForTokenMutation,
  CreateOrderForTokenMutationVariables
} from '../../../graphql-operations';

function getConfirmButtonText(price: number) {
  if (price === 0) {
    return 'Přidat zdarma';
  }

  return `Zaplatit kartou ${formatMoney(price)}`;
}
interface TokenOfferDetailProps {
  clientId: string;
  tokenOfferItem: {
    __typename?: 'TokenOfferItem';
    token: {
      __typename?: 'Token';
      id: string;
      name: string;
      priceFrom: number;
    };
  };
  disabled: boolean;
}

export default function TokenOfferDetail({ clientId, tokenOfferItem, disabled }: TokenOfferDetailProps) {
  const { id, name, priceFrom } = tokenOfferItem.token;

  const history = useHistory();

  const [createOrderMutation] = useMutation<CreateOrderForTokenMutation, CreateOrderForTokenMutationVariables>(
    CreateOrderForTokenDocument
  );

  const { modalProps, confirm } = useConfirmModal<TokenOfferPriceEditFormType>();

  const [buttonPrice, setButtonPrice] = useState(priceFrom);

  const updateButtonPrice = useCallback(
    ({ price }) => {
      setButtonPrice(price);
    },
    [setButtonPrice]
  );

  const createOrder = useCallback(
    (price: number) => {
      return createOrderMutation({
        variables: {
          clientForId: clientId,
          tokenId: id,
          price
        }
      }).then(({ data }) => data && history.push(`/orders/${data.createOrderForToken.orderNumber}`));
    },
    [createOrderMutation, history, clientId, id]
  );

  const onPayClick = useCallback<MouseEventHandler>(
    e => {
      e.preventDefault();

      confirm().then(({ price }) => createOrder(price));
    },
    [createOrder, confirm]
  );

  return (
    <Row className={disabled ? 'text-muted' : ''}>
      <Col sm={8}>
        <h3>{name}</h3>
      </Col>

      <Col sm={4} className="text-right">
        <h3>
          od <MoneyLabel amount={priceFrom} />
        </h3>

        {!disabled && (
          <>
            <Button color="primary" onClick={onPayClick}>
              <span>Vybrat</span>
            </Button>

            <TokenOfferPriceEditModal
              {...modalProps}
              confirmButtonText={getConfirmButtonText(buttonPrice)}
              title="Určení ceny"
              onChange={updateButtonPrice}
              priceFrom={priceFrom}
            />
          </>
        )}
      </Col>
    </Row>
  );
}
