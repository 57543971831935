import NoData from '@monorepo/common/component/NoData';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { PersonDocument, PersonQuery, PersonQueryVariables } from '../../../graphql-operations';
import ClientDetailView, { ClientType } from '../../client/ClientDetailView';
import PersonDetailView from '../PersonDetailView';

interface PersonDetailProps {
  personId?: string;
}

export default function PersonSwitchDetail({ personId }: PersonDetailProps) {
  return (
    <>
      {!!personId && (
        <QueryWithLoader<PersonQuery, PersonQueryVariables> query={PersonDocument} variables={{ id: personId }}>
          {({ data }) => {
            const person = data.person;

            if (!person) {
              return <Redirect to="/page-not-found" />;
            }

            if (person.clientData) {
              const personAsClient: ClientType = {
                ...person,
                clientData: person.clientData,
                __typename: 'Client'
              };

              return <ClientDetailView client={personAsClient} />;
            }

            return <PersonDetailView person={person} />;
          }}
        </QueryWithLoader>
      )}

      {!personId && (
        <>
          <NoData text="Vyberte osobu" />
        </>
      )}
    </>
  );
}
