import EditModal, {
  BaseEditModalProps,
  SaveCompletedHandler,
  VariablesExtractor
} from '@monorepo/common/component/modal/EditModal';
import { failOnMissing } from '@monorepo/common/util/Validation';
import { CreateClientDocument, CreateClientMutation, CreateClientMutationVariables } from 'graphql-operations';
import React, { useCallback } from 'react';
import ClientDetailNew, { EditFormData, validate } from './ClientDetailNew';
import RegistrationSuccessfulModal from './RegistrationSuccessfulModal';

const data2variables: VariablesExtractor<EditFormData, CreateClientMutationVariables> = data => {
  const { degreeBefore, degreeAfter, streetNumberP, streetNumberO, phone } = data;

  if (!streetNumberP && !streetNumberO) {
    console.error('Both streetNumberP and streetNumberO are undefined', data);
    throw new Error('Both streetNumberP and streetNumberO are undefined');
  }

  return {
    degreeBefore,
    degreeAfter,
    firstName: failOnMissing(data, 'firstName'),
    lastName: failOnMissing(data, 'lastName'),
    birthNumber: failOnMissing(data, 'birthNumber'),
    street: failOnMissing(data, 'street'),
    streetNumberP,
    streetNumberO,
    city: failOnMissing(data, 'city'),
    zip: failOnMissing(data, 'zip'),
    sex: failOnMissing(data, 'sex'),
    birthday: failOnMissing(data, 'birthday'),
    phone,
    email: failOnMissing(data, 'email'),
    password: failOnMissing(data, 'password'),
    gdprAgreement: failOnMissing(data, 'gdprAgreement')
  };
};

interface ClientDetailNewModalInnerProps extends BaseEditModalProps {
  title: string;
  onCompleted: (email: string) => void;
}

function ClientDetailNewModalInner({ title, onCompleted, children }: ClientDetailNewModalInnerProps) {
  const handleCompleted: SaveCompletedHandler<CreateClientMutation, EditFormData> = useCallback(
    (data, apolloClient, history, formData) => {
      onCompleted(formData.email || '');
    },
    [onCompleted]
  );

  return (
    <EditModal<EditFormData, CreateClientMutationVariables, CreateClientMutation>
      title={title}
      size="lg"
      tag={ClientDetailNew}
      initialData={{}}
      validate={validate}
      data2variables={data2variables}
      mutation={CreateClientDocument}
      onCompleted={handleCompleted}
      children={children}
    />
  );
}

interface ClientDetailEditModalProps extends BaseEditModalProps {
  title: string;
}

export default function ClientDetailNewModal({ title, children }: ClientDetailEditModalProps) {
  return (
    <RegistrationSuccessfulModal>
      {(open, titleSimple) => {
        return <ClientDetailNewModalInner title={title} children={children} onCompleted={open} />;
      }}
    </RegistrationSuccessfulModal>
  );
}
