import { AppConfigContext } from '@monorepo/common/component/AppConfigProvider';
import HorizontalSeparator from '@monorepo/common/component/HorizontalSeparator';
import React, { useContext } from 'react';
import { Alert, Button, Card, CardBody, CardText, CardTitle, Col, FormGroup } from 'reactstrap';
import ClientDetailNewModal from './ClientDetailNewModal';

export default function LoginPageExtension() {
  const { signUpDisabledMessage } = useContext(AppConfigContext);

  return (
    <>
      <FormGroup row>
        <Col>
          <HorizontalSeparator text="Registrace" />
        </Col>
      </FormGroup>

      {signUpDisabledMessage && (
        <FormGroup row>
          <Col xs={12}>
            <Alert color="warning">{signUpDisabledMessage}</Alert>
          </Col>
        </FormGroup>
      )}

      <FormGroup row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <CardTitle>
                <h5>Chci se stát členem nebo registrovat své dítě</h5>
              </CardTitle>

              <CardText>
                Jsem plnoletý a chci cvičit v oddílu pro dospělé nebo se svým dítětem v oddílu Rodiče a děti. Nebo jsem
                zákonným zástupcem dítěte a chci jej zaregistrovat.
              </CardText>

              <ClientDetailNewModal title="Člen">
                {(handleModalOpen, title) => (
                  <Button onClick={handleModalOpen} disabled={!!signUpDisabledMessage}>
                    Registrovat se
                  </Button>
                )}
              </ClientDetailNewModal>
            </CardBody>
          </Card>
        </Col>
        {/*TODO pujezdsky deeply remove*/}
        {/*<Col xs={12} className="mt-4">*/}
        {/*  <Card>*/}
        {/*    <CardBody>*/}
        {/*      <CardTitle>*/}
        {/*        <h5>Nečlen</h5>*/}
        {/*      </CardTitle>*/}
        {/*      <CardText>Jsem zákonný zástupce a chci zaregistrovat své dítě.</CardText>*/}
        {/*      <PersonDetailNewModal title="Nečlen">*/}
        {/*        {(handleModalOpen, title) => (*/}
        {/*          <Button onClick={handleModalOpen} disabled={!!signUpDisabledMessage}>*/}
        {/*            Registrovat jako nečlen*/}
        {/*          </Button>*/}
        {/*        )}*/}
        {/*      </PersonDetailNewModal>*/}
        {/*    </CardBody>*/}
        {/*  </Card>*/}
        {/*</Col>*/}
      </FormGroup>

      <FormGroup row>
        <Col xs={12} className="text-center">
          V případě problémů nás kontaktujte emailem na <a href="mailto:praha-podoli@sokol.eu">praha-podoli@sokol.eu</a>
          <br />
          <br />
          T.J. Sokol Praha - Podolí
          <br />
          <a href="https://www.sokol-podoli.info/">https://www.sokol-podoli.info</a>
        </Col>
      </FormGroup>
    </>
  );
}
