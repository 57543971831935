import List from '@monorepo/common/component/List';
import PersonNameLabel from '@monorepo/common/component/person/PersonNameLabel';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { match, Route } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import PersonSwitchDetail from '../component/person/switch/PersonSwitchDetail';
import { FamilyDocument, FamilyQuery, FamilyQueryVariables } from '../graphql-operations';

interface PersonsPageProps {
  match: match;
}

export default function PersonsPage({ match }: PersonsPageProps) {
  return (
    <Route
      path={`${match.path}/:personId?`}
      render={props => (
        <QueryWithLoader<FamilyQuery, FamilyQueryVariables> query={FamilyDocument}>
          {({ data, loading, error }) => {
            const persons = data.family;
            const personId = props.match.params.personId || persons[0].id;
            const single = persons.length === 1;
            return (
              <Container>
                <Row>
                  {!single && (
                    <Col xs={3}>
                      <div className="position-fixed-scrollable">
                        <List
                          items={persons}
                          loading={loading}
                          error={error}
                          selectedId={personId}
                          idExtractor={person => person.id}
                          urlExtractor={person => `/persons/${person.id}`}
                        >
                          {person => <PersonNameLabel person={person} />}
                        </List>
                      </div>
                    </Col>
                  )}

                  <Col xs={single ? 12 : 9}>
                    <PersonSwitchDetail personId={personId} />
                  </Col>
                </Row>
              </Container>
            );
          }}
        </QueryWithLoader>
      )}
    />
  );
}
