import AgeLabel from '@monorepo/common/component/AgeLabel';
import { AppConfigContext } from '@monorepo/common/component/AppConfigProvider';
import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import DateLabel from '@monorepo/common/component/DateLabel';
import { IconSolidUserPlus } from '@monorepo/common/component/Icons';
import SexLabel from '@monorepo/common/component/SexLabel';
import PaymentTokenList from 'component/payment/token/PaymentTokenList';
import React, { ReactNode, useContext } from 'react';
import { Col, ColProps, Container, Row } from 'reactstrap';
import { OrderTotalStatusEnum, StampTypeEnum } from '../../graphql-operations';
import CovidAccountBalance from '../covid/CovidAccountBalance';
import PaymentDisciplineForClient from '../payment/discipline/PaymentDisciplineForClient';
import PaymentStampList from '../payment/stamp/PaymentStampList';
import { ClientDetailNewFamilyModal } from '../register/ClientDetailNewFamilyModal';

export interface ClientType {
  __typename?: 'Client';
  id: string;
  personData: {
    __typename?: 'PersonData';
    id: string;
    degreeBefore?: string | null;
    degreeAfter?: string | null;
    firstName: string;
    lastName: string;
    sex?: string | null;
    phone?: number | null;
    email?: string | null;
    note?: string | null;
  };
  clientData: {
    __typename?: 'ClientData';
    id: string;
    birthNumber: string;
    age: number;
    street: string;
    streetNumberO?: string | null;
    streetNumberP?: string | null;
    streetNumberJoined: string;
    city: string;
    zip: number;
    birthday: string;
    paymentsStamp: Array<{
      __typename?: 'PaymentStamp';
      id: string;
      year: number;
      type: StampTypeEnum;
      orderStamp: {
        __typename?: 'OrderStamp';
        id: string;
        priceDiscounted: number;
        orderTotal: { __typename?: 'OrderTotal'; id: string; orderNumber: string };
      };
    }>;
    paymentsDiscipline: Array<{
      __typename?: 'PaymentDiscipline';
      id: string;
      disciplineConfig: {
        __typename?: 'DisciplineConfig';
        id: string;
        discipline: { __typename?: 'Discipline'; id: string; name: string; insuranceReceiptPurpose?: string | null };
        period: { __typename?: 'Period'; id: string; year: number; number: number };
      };
      orderDiscipline: {
        __typename?: 'OrderDiscipline';
        id: string;
        priceDiscounted: number;
        orderTotal: { __typename?: 'OrderTotal'; id: string; orderNumber: string };
      };
      insuranceReceipt?: { __typename?: 'InsuranceReceipt'; id: string } | null;
    }>;
    paymentsToken: Array<{
      __typename?: 'PaymentToken';
      id: string;
      token: {
        __typename?: 'Token';
        id: string;
        name: string;
        period: { __typename?: 'Period'; id: string; year: number; number: number };
      };
      orderToken: {
        __typename?: 'OrderToken';
        id: string;
        price: number;
        orderTotal: {
          __typename?: 'OrderTotal';
          id: string;
          orderNumber: string;
        };
      };
    }>;
    covidAccountBalanceInfo: {
      __typename?: 'CovidAccountBalanceInfo';
      covidAccountBalance: number;
      ordersDiscipline: Array<{
        __typename?: 'OrderDiscipline';
        id: string;
        priceOrig: number;
        priceDiscounted: number;
        covidAccount: number;
        orderTotal: { __typename?: 'OrderTotal'; id: string; orderNumber: string; status: OrderTotalStatusEnum };
        disciplineConfig: {
          __typename?: 'DisciplineConfig';
          id: string;
          period: { __typename?: 'Period'; id: string; year: number; number: number };
          discipline: { __typename?: 'Discipline'; id: string; name: string };
        };
      }>;
    };
  };
}

export function NewClientIcon() {
  return (
    <ClientDetailNewFamilyModal title="Nový člen">
      {(handleModalOpen, title) => (
        <h2 className="text-right">
          <IconSolidUserPlus onClick={handleModalOpen} title={title} className="action-icon text-primary" />
        </h2>
      )}
    </ClientDetailNewFamilyModal>
  );
}

interface ModuleColProps extends ColProps {}

/** Adds margin-top */
export const ModuleCol = ({ children, ...props }: ModuleColProps) => (
  <Col {...props} className="mt-4">
    {children}
  </Col>
);

interface ClientRowProps {
  label: string;
  value?: ReactNode;
  children?: ReactNode;
}

function ClientRow({ label, value, children }: ClientRowProps) {
  return (
    <Row>
      <Col sm={12} md={5}>
        <span className="text-muted">{label}</span>
      </Col>
      <Col sm={12} md={7}>
        {children || value}
      </Col>
    </Row>
  );
}

interface ClientDetailViewProps {
  client: ClientType;
}

export default function ClientDetailView({ client }: ClientDetailViewProps) {
  const { personData, clientData } = client;

  const { currentPeriod } = useContext(AppConfigContext);

  if (!currentPeriod) {
    return null;
  }

  return (
    <>
      <Container>
        {/* header with name and New icon */}
        <Row>
          <Col sm="auto">
            <h2>
              <ClientNameLabel client={client} /> (<AgeLabel age={clientData.age} />)
            </h2>
          </Col>
          <Col />
          <Col sm={2}>
            <NewClientIcon />
          </Col>
        </Row>

        {/* client info */}
        <Row>
          <Col sm={6}>
            <ClientRow label="Rodné číslo" value={clientData.birthNumber} />
            <ClientRow label="Ulice" value={`${clientData.street} ${clientData.streetNumberJoined}`} />
            <ClientRow label="Město" value={clientData.city} />
            <ClientRow label="PSČ" value={clientData.zip} />
            {/*@ts-ignore*/}
            <ClientRow label="Pohlaví">{personData.sex && <SexLabel sex={personData.sex} />}</ClientRow>
            <ClientRow label="Datum narození">
              <DateLabel dateString={clientData.birthday} />
            </ClientRow>
          </Col>
          <Col sm={6}>
            <ClientRow label="Telefon" value={personData.phone} />
            <ClientRow label="E-mail" value={personData.email} />
          </Col>
        </Row>

        {/* Modules */}
        <Row className="mb-3">
          <ModuleCol sm={12} md={8}>
            <PaymentDisciplineForClient
              key={client.id}
              clientId={client.id}
              paymentsDiscipline={clientData.paymentsDiscipline}
            />
          </ModuleCol>

          <ModuleCol sm={4}>
            <PaymentStampList paymentsStamp={clientData.paymentsStamp} clientId={client.id} />
          </ModuleCol>

          <ModuleCol sm={12}>
            <CovidAccountBalance key={client.id} covidAccountBalanceInfo={clientData.covidAccountBalanceInfo} />
          </ModuleCol>

          <ModuleCol sm={6}>
            <PaymentTokenList paymentsToken={clientData.paymentsToken} clientId={client.id} />
          </ModuleCol>
        </Row>
      </Container>
    </>
  );
}
