import NoData from '@monorepo/common/component/NoData';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { OrderDocument, OrderQuery, OrderQueryVariables } from '../../graphql-operations';
import OrderDetailView from './OrderDetailView';

interface OrderDetailProps {
  orderNumber?: string;
}
export default function OrderDetail({ orderNumber }: OrderDetailProps) {
  return (
    <>
      {!!orderNumber && (
        <QueryWithLoader<OrderQuery, OrderQueryVariables> query={OrderDocument} variables={{ orderNumber }}>
          {({ data }) => {
            const order = data.order;

            if (!order) {
              return <Redirect to="/page-not-found" />;
            }

            return (
              <>
                <OrderDetailView order={order} />
              </>
            );
          }}
        </QueryWithLoader>
      )}

      {!orderNumber && (
        <>
          <NoData text="Vyberte objednávku" />
        </>
      )}
    </>
  );
}
