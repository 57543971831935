import { AppConfigContext } from '@monorepo/common/component/AppConfigProvider';
import DateLabel from '@monorepo/common/component/DateLabel';
import NoData from '@monorepo/common/component/NoData';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React, { useContext } from 'react';
import { Alert, Col, Form, Row } from 'reactstrap';
import {
  DisciplineOfferDocument,
  DisciplineOfferQuery,
  DisciplineOfferQueryVariables
} from '../../../graphql-operations';
import DisciplineConfigOfferDetail, { DisciplineConfigOffer } from './DisciplineConfigOfferDetail';

interface DisciplineConfigWaveOfferDetailProps {
  clientId: string;
  disabled: boolean;
  disciplineConfigWaveOffer: {
    availableAfter: string;
    disciplineConfigOffers: DisciplineConfigOffer[];
  };
}

function DisciplineConfigWaveOfferDetail({
  clientId,
  disciplineConfigWaveOffer,
  disabled
}: DisciplineConfigWaveOfferDetailProps) {
  const { availableAfter, disciplineConfigOffers } = disciplineConfigWaveOffer;

  const unavailable = new Date(availableAfter) > new Date();

  return (
    <Row>
      {unavailable && (
        <Col sm={12}>
          <h2>
            Dostupné od <DateLabel dateString={availableAfter} />
          </h2>
          <hr />
        </Col>
      )}

      <Col sm={12}>
        {disciplineConfigOffers.map((configOffer, index) => {
          const { disciplineConfig } = configOffer;
          return (
            <div key={disciplineConfig.id}>
              <DisciplineConfigOfferDetail
                clientId={clientId}
                disciplineConfigOffer={configOffer}
                disabled={unavailable || disabled || disciplineConfig.occupancy.full}
              />
              {index < disciplineConfigOffers.length - 1 && <hr />}
            </div>
          );
        })}
      </Col>
    </Row>
  );
}

interface DisciplineConfigWaveOfferListProps {
  clientId: string;
}

export default function DisciplineConfigWaveOfferList({ clientId }: DisciplineConfigWaveOfferListProps) {
  const { currentPeriod } = useContext(AppConfigContext);

  if (!currentPeriod) {
    return null;
  }

  return (
    <Form>
      <QueryWithLoader<DisciplineOfferQuery, DisciplineOfferQueryVariables>
        query={DisciplineOfferDocument}
        variables={{ clientId }}
        fetchPolicy="network-only"
      >
        {({ data }) => {
          const offer = data.disciplineOffer;

          const offers = offer.disciplineConfigWaveOffers;

          const hasPaymentStamp = offers
            .flatMap(offer => offer.disciplineConfigOffers)
            .every(configOffer => configOffer.hasStamp);

          return (
            <>
              {/* Missing stamp payment error message */}
              {!hasPaymentStamp && (
                <Row>
                  <Col sm={12}>
                    <Alert color="danger">
                      Před zaplacením oddílu musíte mít nejdříve zaplacenou známku na rok {currentPeriod.year}
                    </Alert>
                  </Col>
                </Row>
              )}

              {/* Missing GDPR agreement error message */}
              {!offer.hasGdpr && (
                <Row>
                  <Col sm={12}>
                    <Alert color="danger">
                      Nemáte platný souhlas s GDPR. Dostavte se prosím osobně do kanceláře Sokola a podepište nový.
                    </Alert>
                  </Col>
                </Row>
              )}

              {/*Offers*/}
              {offers.map((waveOffer, idx) => (
                <div key={`${waveOffer.availableAfter}-${idx}`}>
                  <DisciplineConfigWaveOfferDetail
                    clientId={clientId}
                    disciplineConfigWaveOffer={waveOffer}
                    disabled={!hasPaymentStamp || !offer.hasGdpr}
                  />
                </div>
              ))}

              {offers.length === 0 && <NoData text="Momentálně pro Vás nemáme žádné oddíly" />}
            </>
          );
        }}
      </QueryWithLoader>
    </Form>
  );
}
