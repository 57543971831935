import { useMutation } from '@apollo/client';
import LoginLayout from '@monorepo/common/layout/LoginLayout';
import React, { useEffect, useState } from 'react';
import { FinishRegistrationDocument } from '../graphql-operations';

interface RegistrationResultProps {
  success?: boolean;
}

function RegistrationResult({ success }: RegistrationResultProps) {
  if (success) {
    return (
      <span className="text-center">
        Váš email jsme úspěšně ověřili. Nyní se můžete <a href="/login">přihlásit</a>.
      </span>
    );
  }

  if (success === undefined) {
    return <span className="text-center">Dokončování registrace, počkejte prosím.</span>;
  }

  return <span className="text-center">Došlo k chybě, registrace již byla dokončena nebo nikdy neexistovala.</span>;
}

interface FinishRegistrationPageProps {
  token: string;
}

export default function FinishRegistrationPage({ token }: FinishRegistrationPageProps) {
  const [success, setSuccess] = useState<boolean>();

  const [finishRegistration] = useMutation(FinishRegistrationDocument);

  useEffect(() => {
    finishRegistration({
      variables: {
        token
      }
    })
      .then(response => setSuccess(response.data?.finishRegistration ?? false))
      .catch(() => setSuccess(false));

    return () => setSuccess(undefined);
  }, [token, setSuccess, finishRegistration]);
  return (
    <LoginLayout>
      <RegistrationResult success={success} />
    </LoginLayout>
  );
}
