import { AppConfigContext } from '@monorepo/common/component/AppConfigProvider';
import EditModal, {
  BaseEditModalProps,
  SaveCompletedHandler,
  VariablesExtractor
} from '@monorepo/common/component/modal/EditModal';
import { failOnMissing } from '@monorepo/common/util/Validation';
import {
  SignupClientFamilyDocument,
  SignupClientFamilyMutation,
  SignupClientFamilyMutationVariables
} from 'graphql-operations';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ClientDetailNewFamily, { EditFormData, validate } from './ClientDetailNewFamily';

const data2variables: VariablesExtractor<EditFormData, SignupClientFamilyMutationVariables> = data => {
  const { degreeBefore, degreeAfter, streetNumberP, streetNumberO, phone } = data;

  if (!streetNumberP && !streetNumberO) {
    console.error('Both streetNumberP and streetNumberO are undefined', data);
    throw new Error('Both streetNumberP and streetNumberO are undefined');
  }

  return {
    degreeBefore,
    degreeAfter,
    firstName: failOnMissing(data, 'firstName'),
    lastName: failOnMissing(data, 'lastName'),
    birthNumber: failOnMissing(data, 'birthNumber'),
    street: failOnMissing(data, 'street'),
    streetNumberP,
    streetNumberO,
    city: failOnMissing(data, 'city'),
    zip: failOnMissing(data, 'zip'),
    sex: failOnMissing(data, 'sex'),
    birthday: failOnMissing(data, 'birthday'),
    phone,
    gdprAgreement: failOnMissing(data, 'gdprAgreement')
  };
};



interface ClientDetailNewFamilyModalProps extends BaseEditModalProps {
  title: string;
}

export function ClientDetailNewFamilyModal({ title, children }: ClientDetailNewFamilyModalProps) {
  const history = useHistory();

  const handleCompleted: SaveCompletedHandler<SignupClientFamilyMutation, EditFormData> = useCallback(
    (data, apolloClient) => {
      history.push('/persons/' + data.signupClientFamily.id);
      apolloClient.resetStore();
    },
    [history]
  );

  const { signUpDisabledMessage } = useContext(AppConfigContext);
  const disabled = !!signUpDisabledMessage;

  return (
    <EditModal<EditFormData, SignupClientFamilyMutationVariables, SignupClientFamilyMutation>
      title={title}
      size="lg"
      tag={ClientDetailNewFamily}
      validate={validate}
      disabled={disabled}
      data2variables={data2variables}
      mutation={SignupClientFamilyDocument}
      onCompleted={handleCompleted}
      children={children}
    />
  );
}
