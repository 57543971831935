import DisciplineNameLabel from '@monorepo/common/component/discipline/DisciplineNameLabel';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import PeriodLongLabel from '@monorepo/common/component/PeriodLongLabel';
import React, { ReactNode } from 'react';
import { Col, Form, FormGroup } from 'reactstrap';
import { Period } from '../../graphql-operations';

interface InfoRowProps {
  label: string;
  children: ReactNode;
}

const InfoRow = ({ label, children }: InfoRowProps) => {
  return (
    <FormGroup row className="mb-1">
      <Col sm={12} md={3}>
        <span className="text-muted">{label}</span>
      </Col>
      <Col sm={12} md={9}>
        {children}
      </Col>
    </FormGroup>
  );
};

export interface PaymentDisciplineType {
  disciplineConfig: {
    discipline: {
      name: string;
    };
  };
}

interface InsuranceReceiptDetailProps {
  insuranceReceipt: {
    period: Period;
    purpose?: string | null;
    amount: number;
  };
  paymentDiscipline: PaymentDisciplineType;
}

export default function InsuranceReceiptDetail({ insuranceReceipt, paymentDiscipline }: InsuranceReceiptDetailProps) {
  const { period, purpose, amount } = insuranceReceipt;
  return (
    <Form>
      {/* period */}
      <InfoRow label="Období">
        <PeriodLongLabel period={period} />
      </InfoRow>

      {/* payment discipline */}
      {paymentDiscipline && (
        <InfoRow label="Oddíl">
          <DisciplineNameLabel discipline={paymentDiscipline.disciplineConfig.discipline} />
        </InfoRow>
      )}

      {/* purpose */}
      <InfoRow label="Účel platby">
        <span>{purpose}</span>
      </InfoRow>

      {/* amount */}
      <InfoRow label="Částka">
        <MoneyLabel amount={amount} />
      </InfoRow>
    </Form>
  );
}
