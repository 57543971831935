import DateTimeLabel from '@monorepo/common/component/DateTimeLabel';
import List from '@monorepo/common/component/List';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import OrderDetail from '../component/order/OrderDetail';
import { textColorForStatus } from '../component/order/OrderDetailView';
import {
  FamilyOrdersDocument,
  FamilyOrdersQuery,
  FamilyOrdersQueryVariables,
  OrderTotalStatusEnum
} from '../graphql-operations';

interface OrderType {
  __typename?: 'OrderTotal';
  id: string;
  orderNumber: string;
  created: string;
  totalPriceDiscounted: number;
  status: OrderTotalStatusEnum;
}

interface OrderRowProps {
  order: OrderType;
}

function OrderRow({ order }: OrderRowProps) {
  const color = textColorForStatus(order.status);

  return (
    <span className={color}>
      {order.orderNumber} | <DateTimeLabel dateTimeString={order.created} /> |{' '}
      <MoneyLabel amount={order.totalPriceDiscounted} />
    </span>
  );
}

interface OrdersPageProps {
  match: RouteComponentProps;
}

export default function OrdersPage({ match: { match } }: OrdersPageProps) {
  return (
    <Route
      path={`${match.path}/:orderNumber?`}
      render={props => (
        <QueryWithLoader<FamilyOrdersQuery, FamilyOrdersQueryVariables> query={FamilyOrdersDocument}>
          {({ data, loading, error }) => {
            const orders = data.familyOrders;
            const orderNumber = props.match.params.orderNumber;
            return (
              <Container>
                <Row>
                  <Col xs={3}>
                    <div className="position-fixed-scrollable">
                      <List
                        items={orders}
                        loading={loading}
                        error={error}
                        selectedId={orderNumber}
                        idExtractor={order => order.orderNumber}
                        urlExtractor={order => `/orders/${order.orderNumber}`}
                      >
                        {order => <OrderRow order={order} />}
                      </List>
                    </div>
                  </Col>

                  <Col xs={9}>
                    <OrderDetail orderNumber={orderNumber} />
                  </Col>
                </Row>
              </Container>
            );
          }}
        </QueryWithLoader>
      )}
    />
  );
}
