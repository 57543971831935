import ClientNameLabel from '@monorepo/common/component/client/ClientNameLabel';
import { PersonDataType } from '@monorepo/common/component/person/PersonNameLabel';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface ClientNameLinkProps {
  client: {
    id: string;
    personData: PersonDataType;
  };
}

export default function ClientNameLink({ client }: ClientNameLinkProps) {
  return (
    <NavLink to={`/persons/${client.id}`}>
      <ClientNameLabel client={client} />
    </NavLink>
  );
}
