import { useApolloClient } from "@apollo/client";
import { OperationVariables } from "@apollo/client/core";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { DocumentNode } from "graphql";
import _ from "lodash";
import { useCallback, useRef } from "react";
import { PartialNull } from "../../type/Types";
import { PropChangedHandler } from "./EditModal";

export enum ServerValidationState {
  IN_PROGRESS = "IN_PROGRESS",
  VALID = "VALID",
  INVALID = "INVALID",
  ERROR = "ERROR",
}

export function useServerValidation<
  T,
  K extends keyof T,
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  resultExtractor: (data: TData) => boolean,
  variableExtractor: (value: T[K]) => TVariables,
  onPropChange: PropChangedHandler<PartialNull<T>, keyof T>,
  propertyWatch: K,
  propertyResult: K,
  query: DocumentNode | TypedDocumentNode<TData, TVariables>
) {
  const apolloClient = useApolloClient();

  const resultExtractorRef = useRef(resultExtractor);
  resultExtractorRef.current = resultExtractor;

  const variableExtractorRef = useRef(variableExtractor);
  variableExtractorRef.current = variableExtractor;

  const serverCallDebounced = useCallback(
    _.debounce((p: K, value: PartialNull<T>[K] | undefined) => {
      // run server validation
      if (value) {
        // @ts-ignore
        onPropChange(propertyResult, ServerValidationState.IN_PROGRESS);
        // @ts-ignore wtf
        const variables = variableExtractorRef.current(value);

        apolloClient
          .query<TData, TVariables>({
            query,
            variables,
            fetchPolicy: "network-only",
          })
          // .then(resultExtractor)
          .then((response) => {
            if (response.data) {
              if (resultExtractorRef.current(response.data)) {
                // @ts-ignore
                onPropChange(propertyResult, ServerValidationState.VALID);
              } else {
                // @ts-ignore
                onPropChange(propertyResult, ServerValidationState.INVALID);
              }
            } else {
              // @ts-ignore
              onPropChange(propertyResult, ServerValidationState.ERROR);
            }
          });
      } else {
        onPropChange(propertyResult, undefined);
      }
    }, 1000),
    [apolloClient, resultExtractorRef, variableExtractorRef, onPropChange]
  );

  const onPropChangeHooked: PropChangedHandler<PartialNull<T>, K> = useCallback(
    (p, value) => {
      // run server validation
      serverCallDebounced(p, value);

      // send event downstream
      onPropChange(p, value);
    },
    [apolloClient, serverCallDebounced, onPropChange]
  );

  return [onPropChangeHooked];
}
