import PersonNameLabel from '@monorepo/common/component/person/PersonNameLabel';
import SexLabel from '@monorepo/common/component/SexLabel';
import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { NewClientIcon } from '../client/ClientDetailView';

export interface PersonType {
  __typename?: 'Person';
  id: string;
  personData: {
    __typename?: 'PersonData';
    id: string;
    degreeBefore?: string | null;
    degreeAfter?: string | null;
    firstName: string;
    lastName: string;
    sex?: string | null;
    phone?: number | null;
    email?: string | null;
  };
}

interface PersonRowProps {
  label: string;
  value?: ReactNode;
  children?: ReactNode;
}

const PersonRow = ({ label, value, children }: PersonRowProps) => {
  return (
    <Row>
      <Col sm={12} md={5}>
        <span className="text-muted">{label}</span>
      </Col>
      <Col sm={12} md={7}>
        {children || value}
      </Col>
    </Row>
  );
};

interface PersonDetailViewProps {
  person: PersonType;
}

export default function PersonDetailView({ person }: PersonDetailViewProps) {
  const { personData } = person;
  return (
    <Container>
      {/*Header with name and New icon*/}
      <Row>
        <Col sm="auto">
          <h2>
            <PersonNameLabel person={person} />
          </h2>
        </Col>
        <Col />
        <Col sm={2}>
          <NewClientIcon />
        </Col>
      </Row>

      {/*Person info*/}
      <Row>
        <Col sm={6}>
          <PersonRow label="Telefon" value={personData.phone} />
        </Col>
        <Col sm={6}>
          <PersonRow label="E-mail" value={personData.email} />
          {/*@ts-ignore*/}
          <PersonRow label="Pohlaví">{personData.sex && <SexLabel sex={personData.sex} />}</PersonRow>
        </Col>
      </Row>
    </Container>
  );
}
