import SimpleModal from '@monorepo/common/component/modal/SimpleModal';
import React, { EventHandler, ReactNode, SyntheticEvent, useCallback, useState } from 'react';

interface RenderPropAdapterProps {
  open: EventHandler<SyntheticEvent>;
  title: string;
  children: (open: (email: string) => void, title: string) => ReactNode;
  setEmail: (email: string) => void;
}

function RenderPropAdapter({ open, title, children, setEmail }: RenderPropAdapterProps) {
  const adaptedOpen = useCallback(
    (email: string) => {
      setEmail(email);

      // @ts-ignore
      open({
        preventDefault: () => {}
      });
    },
    [setEmail, open]
  );

  return <>{children(adaptedOpen, title)}</>;
}

interface RegistrationSuccessfulModalProps {
  children: (open: (email: string) => void, title: string) => ReactNode;
}

export default function RegistrationSuccessfulModal({ children }: RegistrationSuccessfulModalProps) {
  const [email, setEmail] = useState<string>();

  return (
    <SimpleModal
      title="Registrace zahájena"
      size="md"
      body={() => {
        return (
          <span>
            Registraci dokončíte kliknutím na <strong>odkaz</strong>, který jsme vám zaslali na váš email{' '}
            <strong>{email}</strong>.
          </span>
        );
      }}
    >
      {(open, titleSimple) => {
        return (
          <RenderPropAdapter title={titleSimple} open={open} setEmail={setEmail}>
            {children}
          </RenderPropAdapter>
        );
      }}
    </SimpleModal>
  );
}
