// my stuff
import AppConfigProvider from '@monorepo/common/component/AppConfigProvider';
import AuthenticationApolloProvider from '@monorepo/common/component/AuthenticationApolloProvider';
import AuthenticationContext, { AuthenticationStatus } from '@monorepo/common/component/AuthenticationContext';
import { NotificationConsumer, NotificationProvider } from '@monorepo/common/component/notification';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import ChangePasswordPage from '@monorepo/common/page/ChangePasswordPage';
import ForgottenPasswordPage from '@monorepo/common/page/ForgottenPasswordPage';
import LoginPage from '@monorepo/common/page/LoginPage';
import LogoutPage from '@monorepo/common/page/LogoutPage';
import MaintenancePage from '@monorepo/common/page/MaintenancePage';
import NotFoundPage from '@monorepo/common/page/NotFoundPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useContext } from 'react';
import { RouteProps } from 'react-router';
import { BrowserRouter as Router, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import './App.css';
import LoginPageExtension from './component/register/LoginPageExtension';
import { MeDocument, MeQuery } from './graphql-operations';
import WithHeader from './layout/WithHeader';
import FinishRegistrationPage from './page/FinishRegistrationPage';
import OrdersPage from './page/OrdersPage';
import PersonsPage from './page/PersonsPage';

// Enter on inputs behaves as Tab
// document.addEventListener('keydown', function (event) {
//   if (event.key === 'Enter' && event.target.nodeName === 'INPUT') {
//     const form = event.target.form;
//     if (form) {
//       const index = Array.prototype.indexOf.call(form, event.target);
//       const step = event.shiftKey ? -1 : 1;
//
//       form.elements[Math.max(Math.min(index + step, form.elements.length - 1), 0)].focus();
//       event.preventDefault();
//     }
//   }
// });

interface SecureRouteProps extends RouteProps {
  render: (match: RouteComponentProps) => React.ReactNode;
}

function SecureRoute({ render, ...props }: SecureRouteProps) {
  const { status, loginClient } = useContext(AuthenticationContext);

  if (status === AuthenticationStatus.ANONYMOUS) {
    return <Redirect to="/login" />;
  }

  console.log('Rendering GET_ME');

  return (
    <Route
      {...props}
      render={match => {
        return (
          <QueryWithLoader<MeQuery>
            query={MeDocument}
            onCompleted={data => loginClient(data.me, false)}
            pollInterval={50 * 1000}
          >
            {() => {
              return <WithHeader>{render(match)}</WithHeader>;
            }}
          </QueryWithLoader>
        );
      }}
    />
  );
}

export default function App() {
  return (
    <Router>
      <NotificationProvider>
        <NotificationConsumer>
          {notificationContext => (
            <AuthenticationApolloProvider homePageUrl="/" notificationContext={notificationContext}>
              <AppConfigProvider>
                <Switch>
                  <Route
                    path="/login"
                    render={() => (
                      <WithHeader>
                        <LoginPage extensionRow={() => <LoginPageExtension />} />
                      </WithHeader>
                    )}
                  />
                  <Route
                    path="/forgotten-password/:username?"
                    render={({ match }) => (
                      <WithHeader>
                        <ForgottenPasswordPage forUsername={match.params.username} />
                      </WithHeader>
                    )}
                  />
                  <Route
                    path="/change-password/:token"
                    render={({ match }) => (
                      <WithHeader>
                        <ChangePasswordPage token={match.params.token} />
                      </WithHeader>
                    )}
                  />
                  <Route
                    path="/finish-registration/:token"
                    render={({ match }) => (
                      <WithHeader>
                        <FinishRegistrationPage token={match.params.token} />
                      </WithHeader>
                    )}
                  />
                  <Route
                    path="/logout"
                    render={() => (
                      <WithHeader>
                        <LogoutPage />
                      </WithHeader>
                    )}
                  />

                  <Route
                    path="/maintenance"
                    render={() => (
                      <WithHeader>
                        <MaintenancePage />
                      </WithHeader>
                    )}
                  />

                  <SecureRoute exact path="/" render={match => <PersonsPage match={match.match} />} />
                  <SecureRoute path="/persons" render={match => <PersonsPage match={match.match} />} />
                  <SecureRoute path="/orders" render={match => <OrdersPage match={match} />} />

                  <SecureRoute exact path="/page-not-found" render={() => <NotFoundPage />} />
                  <SecureRoute render={() => <NotFoundPage />} />
                </Switch>
              </AppConfigProvider>
            </AuthenticationApolloProvider>
          )}
        </NotificationConsumer>
      </NotificationProvider>
    </Router>
  );
}
