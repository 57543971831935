import { useMutation } from '@apollo/client';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import StampTypeLabel from '@monorepo/common/component/StampTypeLabel';
import React, { MouseEventHandler, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import {
  CreateOrderForStampDocument,
  CreateOrderForStampMutation,
  CreateOrderForStampMutationVariables,
  Period,
  StampTypeEnum
} from '../../../graphql-operations';

interface StampOfferDetailProps {
  clientId: string;
  stampOfferItem: {
    stampType: StampTypeEnum;
    priceOrig: number;
    priceDiscounted: number;
    period: Pick<Period, 'id' | 'year'>;
  };
  disabled: boolean;
}

export default function StampOfferDetail({ clientId, stampOfferItem, disabled }: StampOfferDetailProps) {
  const { period, stampType, priceOrig, priceDiscounted } = stampOfferItem;

  const history = useHistory();

  const [createOrderMutation] = useMutation<CreateOrderForStampMutation, CreateOrderForStampMutationVariables>(
    CreateOrderForStampDocument
  );

  const createOrder = useCallback(() => {
    return createOrderMutation({
      variables: {
        clientForId: clientId,
        periodId: period.id,
        stampType,
        priceDiscounted
      }
    }).then(({ data }) => data && history.push(`/orders/${data.createOrderForStamp.orderNumber}`));
  }, [createOrderMutation, history, clientId, period.id, stampType, priceDiscounted]);

  const onPayClick = useCallback<MouseEventHandler>(
    e => {
      e.preventDefault();
      createOrder();
    },
    [createOrder]
  );

  return (
    <Row className={disabled ? 'text-muted' : ''}>
      <Col sm={8}>
        <h3>
          {/*@ts-ignore*/}
          <StampTypeLabel type={stampType} /> pro rok {period.year}
        </h3>
      </Col>

      <Col sm={4} className="text-right">
        {priceDiscounted === priceOrig ? (
          <h3>
            <MoneyLabel amount={priceDiscounted} />
          </h3>
        ) : (
          <>
            <del>
              <h3 className="text-muted">
                <MoneyLabel amount={priceOrig} />
              </h3>
            </del>
            <h3>
              <MoneyLabel amount={priceDiscounted} />
            </h3>
          </>
        )}

        {!disabled && (
          <Button color="primary" onClick={onPayClick}>
            {priceDiscounted === 0 ? <span>Přidat zdarma</span> : <span>Zaplatit kartou</span>}
          </Button>
        )}
      </Col>
    </Row>
  );
}
